// Logs when the client route changes
exports.onRouteUpdate = ({ location, prevLocation }) => {
  scrollToAnchor(location, 60)
  return true
}

exports.shouldUpdateScroll = ({routerProps: {location}}) => {
  scrollToAnchor(location, 60)
  return true
}

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */
function scrollToAnchor(location, mainNavHeight = 0) {
  // Check for location so build does not fail
  if (location && location.hash) {
    setTimeout(() => {
      const item = document.querySelector(`${location.hash}`)?.offsetTop
      if (item) {
        window.scrollTo({
          top: item - mainNavHeight,
          behavior: "smooth",
        })
      }
    })
  }

  return true
}
